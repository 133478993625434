<template>
  <BaseTable :data="displayData">
    <BaseElTableColumn
      prop="orderNo"
      label="訂單編號"
      fixed="left"
      width="200"
      align="center"
    />
    <BaseElTableColumn
      fixed="left"
      prop="identity"
      label="姓名"
      width="100"
      align="center"
    />
    <BaseElTableColumn
      prop="phone"
      label="電話號碼"
      width="120"
      align="center"
    />
    <BaseElTableColumn prop="totalItemsPrice" label="總額" align="center" />
    <BaseElTableColumn
      prop="totalDiscountPrice"
      label="已折金額"
      align="center"
    />
    <BaseElTableColumn
      prop="totalPaymentPrice"
      label="已付金額"
      align="center"
    />

    <BaseElTableColumn
      v-if="useEzAIO"
      prop="ezAIOStatus"
      label="刷卡機付款狀態"
      align="center"
    >
      <template slot-scope="scope">
        <Tag :type="get(scope.row.ezAIOStatus, `tagType`)">
          {{ get(scope.row.ezAIOStatus, `label`) }}
        </Tag>
      </template>
    </BaseElTableColumn>

    <BaseElTableColumn prop="orderStatus" label="訂單狀態" align="center">
      <template slot-scope="scope">
        <Tag :type="get(scope.row.orderStatus, `tagType`)">
          {{ get(scope.row.orderStatus, `label`) }}
        </Tag>
      </template>
    </BaseElTableColumn>
    <BaseElTableColumn
      v-if="useBranchStore"
      prop="storeBranch"
      label="操作門市"
      align="center"
    />
    <BaseElTableColumn
      prop="createdAt"
      label="建立時間"
      width="115"
      align="center"
    />
    <BaseElTableColumn label="操作" fixed="right" width="120" align="center">
      <template slot-scope="scope">
        <TableSettingIconButton @click="openRecordDialog(scope.row)" />
      </template>
    </BaseElTableColumn>
  </BaseTable>
</template>

<script>
import { ezAIOPaymentStatusConfig, salesRecordOrderStatusConfig } from '@/config/sales'
import { formatDate } from '@/utils/date'
import { get, map } from 'lodash'
import TableSettingIconButton from '@/components/Button/TableSettingIconButton.vue'

import { computed, defineComponent } from 'vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesRecordTable',
  components: {
    TableSettingIconButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const useBranchStore = computed(() => {
      return checkAction('admin.branch.page')
    })
    const useEzAIO = computed(() => checkAction('adminView.salesRecord.showSalesPaymentOrder'))

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        let identity = '-'
        if (item.identity) {
          identity = identityTrans(item.identity)
        } else if (item.Member) {
          identity = item.Member.UserInfo.name
        } else {
          identity = '非會員'
        }

        const paymentStatus = get(item, 'SalesPaymentOrder.status', null)

        return {
          id: item.id,
          orderNo: item.orderNo,
          identity,
          phone: item.phone,
          totalItemsPrice: item.totalItemsPrice,
          totalDiscountPrice: item.totalDiscountPrice,
          totalPaymentPrice: item.totalPaymentPrice,
          ezAIOStatus: paymentStatus ? get(ezAIOPaymentStatusConfig, paymentStatus) : { label: '-', tagType: 'info' },
          orderStatus: get(salesRecordOrderStatusConfig, item.status) || {},
          storeBranch: get(item.Branch, 'name', '-'),
          createdAt: formatDate(item.createdAt),

        }
      })
    })

    const identityTrans = (text) => {
      const dict = {
        frequenter: '熟客',
        platform: '平台戶',
        passerby: '過路客',
        foreigner: '外國客',
        bookinger: '預約客',
        other: '其他',
      }
      return dict[text]
    }

    const openRecordDialog = (row) => {
      emit('openRecord', row)
    }

    return { displayData, useBranchStore, useEzAIO, get, openRecordDialog }
  },
})

</script>

<style lang="postcss" scoped>
</style>
