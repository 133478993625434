<template>
  <div class="sales-record-list">
    <PageTitle
      title="銷售紀錄列表"
      btn="新增"
      @btnClick="$router.push({ name: 'SalesRecordLogin' })"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="orderSearch"
        clearable
        placeholder="輸入訂單編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-model="phoneSearch"
        clearable
        placeholder="輸入電話號碼"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-if="useBranchStore"
        v-model="branchStoreSearch"
        clearable
        placeholder="輸入操作門市名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <SalesRecordTable
        :tableData="tableData"
        @openRecord="openRecordDialog"
      />

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      :visible.sync="passwordDialog"
      :close-on-click-modal="false"
      @close="password = ''"
    >
      <div slot="title">
        <span class="dialog-title">輸入密碼</span>
        <el-divider class="dialog-hr" />
      </div>
      <BaseElForm :model="password">
        <span class="dialog-content">密碼</span>
        <BaseElFormItem :rules="{ required: true }">
          <BaseElInput
            v-model="password"
            style="margin-top: 10px; margin-bottom: 20px"
            type="password"
            show-password
            placeholder="請輸入密碼"
          />
        </BaseElFormItem>
      </BaseElForm>
      <div class="flex-center">
        <BaseElButton
          type="primary"
          style="width: 100px; margin: 0 auto"
          @click="cancelSalesRecord"
        >
          確認
        </BaseElButton>
      </div>
    </el-dialog>

    <!-- Dialog -->
    <el-dialog
      :visible.sync="alertDialog"
      :close-on-click-modal="false"
      width="440px"
      @close="password = ''"
    >
      <div slot="title">
        <span class="dialog-title">提醒</span>
        <el-divider class="dialog-hr" />
      </div>
      <div class="dialog-content">
        尚未設定銷售紀錄作廢密碼 <br>
        請前往「基本參數設定 → 銷售紀錄參數設定」進行設定。
      </div>
      <div class="flex-center">
        <BaseElButton
          type="primary"
          style="width: 140px; margin-top: 40px"
          @click="alertDialog = false"
        >
          關閉
        </BaseElButton>
      </div>
    </el-dialog>

    <SalesRecordDialog
      v-if="showRecordDialog"
      :selectedTarget="selectRecord || {}"
      :useShopRebate="useShopRebate"
      @open-record-note-dialog="showRecordNoteDialog = true"
      @close-sales-record-dialog="showRecordDialog = false"
      @show-password-dialog="
        ;(showRecordDialog = false),
          config.useInvalidPassword
            ? (passwordDialog = true)
            : (alertDialog = true)
      "
    />

    <!-- Dialog -->
    <el-dialog
      class="sales-record-dialog"
      title="備註"
      :visible.sync="showRecordNoteDialog"
      :close-on-click-modal="false"
      width="592px"
    >
      <BaseElInput
        v-model="recordNote"
        type="textarea"
        :rows="10"
        placeholder="請輸入備註文字"
        maxlength="100"
        show-word-limit
      />
      <span slot="footer" class="dialog-footer">
        <BaseElButton
          plain
          style="width: 140px"
          @click="showRecordNoteDialog = false"
        >
          關閉
        </BaseElButton>
        <BaseElButton
          type="primary"
          style="width: 140px"
          @click="updateSaleRecordNote"
        >
          儲存
        </BaseElButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetSalesRecord,
  GetSalesRecordCount,
  CancelSalesRecord,
  FindSalesRecord,
  UpdateSalesRecord,
  GetSalesConfig,
} from '@/api/sales'
import MixinFunc from '@/components/MixinFunc.vue'
// Utils
import dayjs from '@/lib/dayjs'
import { get } from 'lodash'
import SalesRecordDialog from './components/SalesRecordDialog.vue'
import { FindShopRebate } from '@/api/rebate'

import { ref, computed, onMounted } from 'vue'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import { useTable } from '@/use/table'
import { apiFormatAdaptor } from '@/utils/api'
import { salesRecordOrderStatusConfig } from '@/config/sales'
import SalesRecordTable from './components/SalesRecordTable.vue'
export default {
  name: 'SalesRecordList',
  components: { SalesRecordTable, SalesRecordDialog },
  mixins: [MixinFunc],
  setup () {
    const branchStoreSearch = ref(null)
    const phoneSearch = ref('')
    const orderSearch = ref('')
    const config = ref({})
    const alertDialog = ref(false)
    const passwordDialog = ref(false)
    const password = ref('')
    const dialogType = ref('create')
    const showRecordDialog = ref(false)
    const selectRow = ref(null)
    const selectRecord = ref(null)
    const selectUnitRecord = ref({})
    const shopRebate = ref({})
    const showRecordNoteDialog = ref(false)
    const recordNote = ref('')
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
    } = useTable()

    const useShopRebate = computed(() => {
      return Boolean(checkAction('adminView.member.shopCashback') && shopRebate.value.id)
    })

    const useBranchStore = computed(() => {
      return checkAction('admin.branch.page')
    })

    const dialogTitle = computed(() => {
      return dialogTitle(dialogType.value, {
        create: '新增銷售類別',
        update: '更新銷售類別',
      })
    })

    onMounted(async () => {
      await getSalseConfig()
      await refresh()

      if (checkAction('adminView.member.shopCashback')) {
        findShopRebate()
      }
    })

    const findShopRebate = async () => {
      const [res, err] = await FindShopRebate({
        shopId: shopId.value,
      })
      if (err) return window.$message.error(err)
      shopRebate.value = res
    }

    const recordDateFromat = () => {
      if (!selectRecord.value) return ''
      return dayjs(selectRecord.value.createdAt).format('YYYY/MM/DD HH:mm')
    }

    const unitRecord = () => {
      const salesItem = selectRecord.value.SalesRecordItems
      const unitRecord = {}

      salesItem.forEach((item) => {
        if (item.SalesUnit) {
          const name = item.SalesUnit.name
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }

        if (!item.SalesUnit) {
          const name = 'nullUnit'
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }
      })
      selectUnitRecord.value = unitRecord
    }

    const getSalseConfig = async () => {
      try {
        const res = await GetSalesConfig({ shopId: shopId.value })
        config.value = res
      } catch (error) {
        console.log(error)
        window.$message.error(error || error.message)
      }
    }

    const findSalesRecord = async () => {
      try {
        selectRecord.value = await FindSalesRecord({
          shopId: shopId.value,
          id: selectRow.value.id,
        })
        recordNote.value = selectRecord.value.note
        unitRecord()
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const updateSalesRecord = async () => {
      try {
        const record = await UpdateSalesRecord({
          shopId: shopId.value,
          id: selectRow.value.id,
          note: recordNote.value,
        })
        selectRow.value.note = record.note
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const refresh = async () => {
      loading.table = true
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        phone: phoneSearch.value === '' ? undefined : phoneSearch.value,
        orderNo: orderSearch.value === '' ? undefined : orderSearch.value,
        branchName: branchStoreSearch.value || undefined,
      }
      await Promise.allSettled([
        fetchData(apiFormatAdaptor(GetSalesRecord), payload),
        fetchDataCount(apiFormatAdaptor(GetSalesRecordCount), payload),
      ])
      loading.table = false
    }

    const openRecordDialog = async (row) => {
      selectRow.value = row
      await findSalesRecord()
      showRecordDialog.value = true
    }

    const cancelSalesRecord = async () => {
      try {
        if (password.value === '') {
          window.$message.warning('請輸入密碼 !')
          return
        }
        await CancelSalesRecord({
          shopId: shopId.value,
          id: selectRow.value.id,
          password: password.value,
        })
        await refresh()
        window.$message.success('已作廢銷售紀錄 !')
        passwordDialog.value = false
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const updateSaleRecordNote = async () => {
      showRecordNoteDialog.value = false
      await updateSalesRecord()
      await findSalesRecord()
    }

    return {
      get,
      tableData,
      loading,
      branchStoreSearch,
      phoneSearch,
      orderSearch,
      config,
      alertDialog,
      passwordDialog,
      password,
      dialogType,
      showRecordDialog,
      selectRow,
      selectRecord,
      selectUnitRecord,
      tableOptions,
      shopRebate,
      showRecordNoteDialog,
      recordNote,
      pageStartIndex,
      useShopRebate,
      useBranchStore,
      dialogTitle,
      findShopRebate,
      recordDateFromat,
      unitRecord,
      getSalseConfig,
      findSalesRecord,
      updateSalesRecord,
      refresh,
      openRecordDialog,
      cancelSalesRecord,
      updateSaleRecordNote,
      tableDataCount,
      salesRecordOrderStatusConfig,
    }
  },
}
</script>

<style scoped lang="scss">
.unit-block {
  margin-bottom: 25px;
  .unit-name {
    background: #e7e7f3;
    padding: 5px 10px;
    margin-bottom: 8px;
  }

  .record-item {
    margin-bottom: 10px;

    .item-name {
      margin-right: 10px;
    }

    .item-quantity {
      flex-shrink: 0;
      font-weight: 500;
    }
  }
}

.payment {
  margin: 15px 0;
  .title {
    font-size: 21px;
  }
}

.dialog-title {
  font-size: 18px;
  @apply text-primary-100 font-bold;
}

.dialog-content {
  @apply text-center font-medium text-[18px] leading-[28px] text-gray-100;
  // font-family: Noto Sans TC;
  // font-style: normal;
}

.dialog-hr {
  @apply absolute left-0 right-0 top-[55px];
}

.price {
  font-weight: 500;
}

::v-deep .el-textarea {
  @apply w-full;
}
</style>
